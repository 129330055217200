module.exports = [{
      plugin: require('../../../../../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../../node_modules/gatsby-plugin-why-did-you-render/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://652185691fcc492e91f35a546c03548b@o27775.ingest.sentry.io/5875806"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
