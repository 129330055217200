// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-carrier-upload-tsx": () => import("./../../../src/pages/carrier-upload.tsx" /* webpackChunkName: "component---src-pages-carrier-upload-tsx" */),
  "component---src-pages-customer-home-tsx": () => import("./../../../src/pages/customer-home.tsx" /* webpackChunkName: "component---src-pages-customer-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-new-qa-lead-auto-tsx": () => import("./../../../src/pages/new-qa-lead-auto.tsx" /* webpackChunkName: "component---src-pages-new-qa-lead-auto-tsx" */),
  "component---src-pages-new-qa-lead-ma-tsx": () => import("./../../../src/pages/new-qa-lead-ma.tsx" /* webpackChunkName: "component---src-pages-new-qa-lead-ma-tsx" */),
  "component---src-pages-new-qa-lead-ms-tsx": () => import("./../../../src/pages/new-qa-lead-ms.tsx" /* webpackChunkName: "component---src-pages-new-qa-lead-ms-tsx" */),
  "component---src-pages-oauth-tsx": () => import("./../../../src/pages/oauth.tsx" /* webpackChunkName: "component---src-pages-oauth-tsx" */),
  "component---src-pages-redirect-tsx": () => import("./../../../src/pages/redirect.tsx" /* webpackChunkName: "component---src-pages-redirect-tsx" */),
  "component---src-pages-user-not-found-tsx": () => import("./../../../src/pages/user-not-found.tsx" /* webpackChunkName: "component---src-pages-user-not-found-tsx" */),
  "component---src-pages-user-oauth-apps-tsx": () => import("./../../../src/pages/user-oauth-apps.tsx" /* webpackChunkName: "component---src-pages-user-oauth-apps-tsx" */)
}

