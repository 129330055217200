import React from 'react'
import "./src/styles/global.css"

export const onClientEntry = () => {
  if (process.env.NODE_ENV === 'development') {
    // const whyDidYouRender = require('@welldone-software/why-did-you-render')
    // whyDidYouRender(React, {
    //   trackAllPureComponents: true
    // });
  }
}
